import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";

import { filterSafetyCenterForApproachChanges } from '@helpers';
import { IconRollback } from '@icons';
import { StyledTableCell } from '@components/Table';
import { Page, FullPageContent, TopBarContent } from '@components/Grid';
import {SafetyCentreHistoryConfirmDialog } from '@components/Modal';
import { updateSafetyCentre, getSafetyCentreHistory, searchSafetyCentres, selectSafetyCentre } from "@actions";

const SafetyCentreHistory = () => {
  const { scid, approachId } = useParams();
  const [confirmData, setConfirmData] = React.useState(false);
  const { selected, safetyCentres, safetyCentreHistory } = useSelector(state => state.configuration);
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!safetyCentres) {
      dispatch(searchSafetyCentres(scid));
    }

    if (!selected) {
      dispatch(selectSafetyCentre(scid));
    }
  }, [safetyCentreHistory, scid, dispatch, safetyCentres, selected]);

  useEffect(() => {
    if (!safetyCentreHistory) {
      dispatch(getSafetyCentreHistory(scid));
    }
  }, [dispatch, safetyCentreHistory, scid]);

  if (!user.isCvscAdmin) {
    return <Navigate replace to="/" />;
  };
  
  const rollbackApproach = async () => {
    const approaches = safetyCentre.approaches.map(a => {
      if (a.id === confirmData.id) {
        return { ...a, ...confirmData };
      } else {
        return a;
      }
    });
    const payload = { safetyCentreId: safetyCentre.id, updateRequest: { ...safetyCentre, approaches: approaches } };
    try {
      await dispatch(updateSafetyCentre(payload));
      setConfirmData(null);
      dispatch(getSafetyCentreHistory(safetyCentre.id));
      navigate(`/safety-centres/${safetyCentre.id}/config`);
    } catch (error) {
      setConfirmData(null);
    }
  };

  const handleRollback = (approach) => {
    setConfirmData(approach);
  }

  const calculateSafetyCentre = () => {
    if (selected === undefined) {
      return undefined;
    } else if (selected && safetyCentres[selected]) {
      return safetyCentres[selected];
    } else {
      return null;
    }
  };
  let safetyCentre = calculateSafetyCentre();

  const columns = [
    { id: 'name', label: 'Approach Name', },
    { id: 'created', label: 'Date', format: (value) => format(parseISO(value), 'dd/MM/yyyy HH:mm:ss') },
    { id: 'createdBy', label: 'Changed By', },
    { id: 'visibilityDelay', label: 'Visibility Delay (seconds)' },
    { id: 'visibilityPeriod', label: 'Visibility Period (seconds)',  },
    { id: 'visibilityZoneFinish', label: 'Visibility Zone Finish (meters)' },
    { id: 'visibilityZoneStart', label: 'Visibility Zone Start (meters)' },
    {
      id: "action",
      label: "Rollback",      
      format: (value) => (<IconButton onClick={() => { handleRollback({ 
        id: value.id, 
        name: value.name,
        visibilityDelay: value.visibilityDelay,
        visibilityPeriod: value.visibilityPeriod,
        visibilityZoneFinish: value.visibilityZoneFinish,
        visibilityZoneStart: value.visibilityZoneStart
      }) }} color="secondary" ><IconRollback color="secondary" /></IconButton>)
    },
  ];

  let approachSpecificHistory = safetyCentreHistory?.map(sc => {
    const req = sc.updateRequest?.approaches?.find(a => a.id === Number(approachId));
    return req ? {
      id: req.id,
      name: req.name,
      created: sc.created,
      createdBy: sc.createdBy,
      visibilityDelay: req.visibilityDelay,
      visibilityPeriod: req.visibilityPeriod,
      visibilityZoneFinish: req.visibilityZoneFinish,
      visibilityZoneStart: req.visibilityZoneStart
    } : null
  });
  const validData = approachSpecificHistory?.filter(Boolean);

  const calculateRowData = () => {
    if (validData && validData.length === 1) {
      return validData;
    } else if (!validData || validData.length === 0) {
      if (approachSpecificHistory?.length > 0 && safetyCentreHistory?.length > 0) {
        return [];
      }
    } else {
      return filterSafetyCenterForApproachChanges(validData);
    }
  };
  const rowData = calculateRowData();
  
  return (
    <Page>
      <FullPageContent >
        <TopBarContent>
          <Typography variant="h1" >{safetyCentre ? safetyCentre.name : ""}</Typography>
        </TopBarContent>

        <div className="py-2">
          <Typography variant='h3' color="primary">Approach History</Typography>
        </div>

        <div className="flex w-full overflow-y-auto">

          <TableContainer >
            <Table stickyHeader >
              <TableHead>
                <TableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData ? rowData
                  .map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {
                                column.format ? 
                                column.format(column.id === 'action' ? row : value) : 
                                value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                ) : null
              }
              </TableBody>
            </Table>
          </TableContainer>

          <SafetyCentreHistoryConfirmDialog handleYes={rollbackApproach} handleNo={() => setConfirmData(null)} open={!!confirmData} />

        </div>
      </FullPageContent>
    </Page>
  );
}

export default SafetyCentreHistory;