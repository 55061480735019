import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import { User, Burger, Logo, MapImage, Check, Tick, Warning, ErrorImage, Print, Cog, HistoryImage, Rollback, Visibility } from "../images";

const Root = styled(Box)(({ theme, ...props }) => ({
  width: props.size,
  height: props.size,
  minWidth: props.size,
  minHeight: props.size,
  fontSize: props.size,
  lineHeight: props.size,
  color: {
    primary: theme.palette.primary.text,
    secondary: theme.palette.secondary.text,
    info: theme.palette.info.text,
    success: theme.palette.success.text,
    warning: theme.palette.warning.text,
    action: theme.palette.action.active,
    error: theme.palette.error.text,
    disabled: theme.palette.action.disabled,
    contrast: theme.palette.getContrastText(theme.palette.primary.text),
    inherit: undefined,
  }[props.color],
}));

const SvgIcon = forwardRef((props, ref) => {
  const { children, size, sx, className, color } = props;

  return (
    <>
      <Root
        {...props}
        component="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        className={`shrink-0 fill-current ${className}`}
        ref={ref}
        size={size}
        sx={sx}
        color={color}
      >
        {children}
      </Root>
    </>
    )
  
});

SvgIcon.propTypes = {
  children: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object,
  color: PropTypes.oneOf([
    'inherit',
    'disabled',
    'primary',
    'secondary',
    'action',
    'error',
    'info',
    'success',
    'warning',
    'contrast'
  ]),
};

SvgIcon.defaultProps = {
  children: () => (<svg></svg>),
  size: 18,
  sx: {},
};
const createIconComponent = (IconComponent) => (props) => (
  <SvgIcon {...props}>
    <IconComponent />
  </SvgIcon>
);

export const IconUser = createIconComponent(User);
export const LogoWhite = createIconComponent(Logo);
export const IconBurger = createIconComponent(Burger);
export const IconMap = createIconComponent(MapImage);
export const IconCheck = createIconComponent(Check);
export const IconTick = createIconComponent(Tick);
export const IconWarning = createIconComponent(Warning);
export const IconError = createIconComponent(ErrorImage);
export const IconVisibility = createIconComponent(Visibility);
export const IconPrint = createIconComponent(Print);
export const IconCog = createIconComponent(Cog);
export const IconHistory = createIconComponent(HistoryImage);
export const IconRollback = createIconComponent(Rollback);